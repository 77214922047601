.game-over-screen{
  width: calc(100% - 336px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 102px;
  background-color: white;
  /* overflow: hidden; */
  text-align: left;
}


.game-over-body{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}
.main-text{
  font-size: 30px;
  font-weight: bold;
  color: black;
  margin-bottom: 24px;
  text-align: left;
  /* width: 100%; */
  text-wrap: nowrap;
  white-space: nowrap;
}



.thank-you-text{
  font-size: 16px;
  color: black;
  margin-bottom: 16px;
}

.game-over-footer{
  display: flex;
  align-items: center;
  width: calc(100% - 336px);
}

.share-button-icon{
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.share-button-area{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 44px;
  margin-bottom: 24px;
}

.share-score-btn-mobile{
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  gap: 8px;
  margin-bottom: 28px;
  cursor: pointer;
}



.share-score-btn-mobile img{
  width: 28px;
}

@media (max-width: 768px) {
  .game-over-footer{
    flex-direction: column;
    width: 100%;
  }
  .game-over-screen{
    width: 100%;
    padding-left: 0;
    padding: 30px;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: scroll;
  }
  
}


@media (min-width: 1920px) {
  .game-over-screen{
    width: calc(100% - 336px);

    justify-content: center;
    align-items: center;
  }

  .game-over-body{
    max-width: 500px;
  }
  
  .game-over-footer{
    display: block;
    width:  416px;
  }
}