.wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrapper {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.removeOutline:focus-visible{
  outline: none;
}

.removeOutline::placeholder{
  font-family: 'Open Sans';
}

textarea::placeholder {
  font-size: 16px;
  font-family: 'Open Sans';
}

.applyContainer div{
  position: relative;
}

.applyButton:hover {
  box-shadow: 6px 6px 0px 0px #000 !important;
  top:-3px;
}

