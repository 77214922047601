.share-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #764fbe;
  color: white;
  padding: 32px;
  width: 352px;
  height: 476px;
  position: relative;

}

.smile{
  position: absolute;
  left: 39px;
  bottom: 44px;
}

.star{
  position: absolute;
  right: 27.55px;
  top: 64px;
}

.main-share-container {
  /* width: calc(100% - 336px); */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 194px;
}

.share-title {
  font-size: 28px;
  line-height: 37px;
  font-weight: bold;
  text-align: center;
}

.share-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}



.timer-score {
  font-size: 12px;
  font-weight: 400;
}

.game-score-text {
  margin-top: 11px;
  margin-bottom: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.question-score {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}


.taleas-logo-share{
  width: 47.59px;
  height: 45.55px;
  position: absolute;
  bottom: 32px;
}

@media (max-width:768px) {
  .share-container{
    width: calc(100% - 32px);
  }
}