.progressbar {
  position: relative;
  /* overflow: hidden; */
  width: 350px;
  height: 35px;
  border-radius: 100px;
  background-color: #eee;

}

.progressPercent {
  font-weight: 600;
  font-family: 'Franklin Gothic Medium';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #eee;
  text-shadow:
    -1px 0 #555,
    0 1px #555,
    1px 0 #555,
    0 -1px #555;
}

.progress-container {
  width: calc(100% - 336px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;

}

.progress-title {
  font-size: 34px;
  font-weight: 600;
  position: relative;
}

.title-effect {
  position: absolute;
  top: -25px;
  left: -45px;
}


@media (max-width: 768px) {
  .progress-container {
  width: 100%;
  }
  
}