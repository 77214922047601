.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.leave-modal {
  background-color: white;
  z-index: 1000;
  width: 750px;
  height: 460px;
  overflow: hidden;
  border-radius: 5px;
  border: 10px solid black;
  animation: roadRunnerIn 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes roadRunnerIn {
  0% {
    transform: translateX(-1500px) skewX(30deg) scaleX(1.3);
  }
  70% {
    transform: translateX(30px) skewX(0deg) scaleX(0.9);
  }
  100% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.leaving-modal-title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 41px;
}
.leaving-modal-title span {
  font-size: 24px;
  font-weight: bold;
  margin-left: 36px;
}
.leaving-modal-title img {
  padding-right: 38px;
  cursor: pointer;
}

.leaving-info {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.leave-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 31px;
  margin-top: 30px;
}
.cnt-btn {
  box-shadow: 4px 4px 0px black;
  border: 1px solid black;
  color: black;
  font-size: 16px;
  cursor: pointer;
  stroke: none;
  width: 170px;
  height: 50px;
  border-radius: 6px;
  &:hover{
    background-color: #c9c6c6;
  
  }
}

.leave-button {
  box-shadow: 4px 4px 0px black;
  border: 1px solid black;
  cursor: pointer;
  font-size: 16px;
  color: white;
  stroke: none;
  width: 170px;
  height: 50px;
  border-radius: 6px;
  background-color: #7750bf;
  &:hover {
    background-color: #5f3f9f;
  }
}

.leaving-divider{
  background: #CECECE;
  opacity: 0.7;
  margin-top: 23px;
  margin-bottom: 32px;
}

.footer-divider{
  margin-top: 34px;
  width: 100%;
  background: #CECECE;
  opacity: 0.7;
}

.leav-info-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 768px) {

  .leave-modal {
    width: calc(100% - 36px);
    padding-bottom: 22px;
    height: fit-content;
  }


  .leave-buttons{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 15px;
  }
  

  .leaving-info{
    width: calc(100% - 10px);
  }

}
