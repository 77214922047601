.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  background-color: white;
  z-index: 1000;
  width: 750px;
  height: 600px;
  overflow: hidden;
  border-radius: 5px;
  border: 10px solid black;
  animation: roadRunnerIn 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes roadRunnerIn {
  0% {
    transform: translateX(-1500px) skewX(30deg) scaleX(1.3);
  }
  70% {
    transform: translateX(30px) skewX(0deg) scaleX(0.9);
  }
  100% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-title {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 32px 0px 16px 0px;
  justify-content: center;
}

.modal-question {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 0px 17px 0px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}

.wrong {
  border: 10px solid #ee6666 ;
}

.wrong-answer{
  opacity: 1 !important;
  color: white !important;
  background-color: #ee6666 !important;
}

.correct-answer{
  opacity: 1 !important;
  color: white !important;
  background-color: #7dc937 !important;
}

.correct {
  border: 10px solid #7dc937 ;
}

.modal-code {
  max-height: 140px;
  overflow: hidden;
  border-bottom: 1px solid #cccccc;
}

.modal-answer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 34px;
  padding-top: 34px;
  
}

.answer-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.answer-btn {
  opacity: 0.6;
  box-shadow: 4px 4px black;
  border: 1px solid black;
  color: black;
  stroke: none;
  width: 300px;
  height: 50px;
  border-radius: 6px;
  background-color: transparent;
}

.answer-btn:hover {
  opacity: 1;
}


@media (max-width:768px) {
  .modal{
    width: calc(100% - 36px);
  padding-bottom: 22px;
  height: fit-content;
  }
.keisi-bot{
  /* width: 112px; */
  flex: 1;
  padding-left: 16px;
}
  .modal-answer{
    gap: 10px;
    justify-content: flex-start;
  }

  .modal-question{
    font-size: 16px;
   
  }

  .answer-btn{
    width: 150px;
  }
  .answer-buttons{
    flex: 3;
  }

  .modal-code{
    max-height: 180px;
    overflow: scroll;
    
    padding-left: 10px;
    padding-right: 10px;
  }
}
