.removeOutline:focus-visible{
    outline: none;
}
input{
    font-family: 'Open Sans';

}
input::placeholder{
    font-size: 14px;
    font-family: 'Open Sans';
}

textarea{
    font-family: 'Open Sans';
}

textarea::placeholder {
    font-size: 16px;
    font-family: 'Open Sans';
}