.stopwatch{
    font-size:18px;
    width: 175px;
    height: 50px;
    border-radius: 6px;
    background-color: white;
    border: 1px solid black;
    box-shadow: 4px 4px 0px black;
    display: flex;
  justify-content: center;
  align-items: center;
    font-family: 'Consolus', monospace;

}

.time-text{
  animation: heartbeat 1s infinite alternate;
}

@keyframes heartbeat {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
  }