.score-row{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}


@media (max-width:768px) {
    .score-row{
        scale: 0.9;
        gap: 10px;
    }
    
}