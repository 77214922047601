.App-maze {
    font-family: sans-serif;
    width: calc(100% - 336px);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-bottom: 50px;
    &:focus {
      outline: none;
    }
  }
  
  
  
  
  
  .setting {
    align-self: flex-start;
    padding: 5px 10px;
  
    button {
      font-size: 15px;
    }
  
    input {
      font-size: 20px;
    }
  }
  
  .topWall {
    border-top: 3px solid #7750bf;
  }
  
  .rightWall {
    border-right: 3px solid #7750bf;
  }
  
  .bottomWall {
    border-bottom: 3px solid #7750bf;
  }
  
  .leftWall {
    border-left: 3px solid #7750bf;
  }
  
  .letter{
    div{
         background: salmon;
         /* background-image: url("../assets//keisi.svg"); */
         background-repeat: no-repeat;
         height: 100%;
         width: 100%;
         border-radius: 50%;
         display: block;
    }
  }
  
  .letterT{
    div{
     
      background-image: url("../assets/Color/T.svg");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      /* border-radius: 50%; */
      display: block;
  }
  
  }
  
  
  .letterA{
    div{
     
        background-image: url("../assets/Color/A.svg");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
    
      display: block;
  }}
  
  .letterL{
    div{
     
      background-image: url("../assets/Color/L.svg");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      /* border-radius: 50%; */
      display: block;
  }
  
  }
  
  .letterE{
    div{
     
      background-image: url("../assets/Color/E.svg");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      /* border-radius: 50%; */
      display: block;
  }
  
  }
  
  .letterA2{
    div{
     
      background-image: url("../assets/Color/A2.svg");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      /* border-radius: 50%; */
      display: block;
  }
  
  }
  
  .letterS{
    div{
     
      background-image: url("../assets/Color/S.svg");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      /* border-radius: 50%; */
      display: block;
  }
  
  }
  
  
  
  
  .sol {
    div {
      height: 50%;
      width: 50%;
      border-radius: 50%;
      background: peachpuff;
      display: inline-block;
    }
  }
  
  .currentPosition {
    div {
      /* background: salmon; */
      background-image: url("../assets/RoboKeisi/KeisiBot.svg");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      /* border-radius: 50%; */
      display: block;
    }
  }
  
  .destination {
    div {
      background: #EE6666;
      object-fit: contain;
      background-image: url("../assets/Color/Destinations.svg");
      background-repeat: no-repeat;
      background-position: 50%;
      border: 1px solid black;
      scale: 0.9;
      border-radius: 50%;
     
      height: 100%;
      width: 100%;
      display: block;
    }
  }
  
  .currentPosition.destination {
    div {
      animation: rainbowBall 5s infinite;
    }
  }
  
  @keyframes rainbowBall {
    0% {
      background-color: gold;
    }
    15% {
      background-color: coral;
    }
    29% {
      background-color: papayawhip;
    }
    43% {
      background-color: darkseagreen;
    }
    57% {
      background-color: lightcyan;
    }
    71% {
      background-color: dodgerblue;
    }
    84% {
      background-color: violet;
    }
    100% {
      background-color: indigo;
    }
  }
  .syntax {
    width: 400px;
    height: auto;
  }
  #maze {
    margin-top: 60px;
    margin-bottom: 15px;
    border-collapse: collapse;
    td {
      height: 40px;
      width: 40px;
    }
  }
  
/*   

  .control-buttons button{
  
    margin: 10px;
    padding: 10px;
    font-size: 20px;
    width: 60px;
    border-radius: 5px;
    background-color: #7750bf;
    color: white;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #5f3f9f;
    }
  } */

  .control-buttons{
    display: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
 
  }
  
.up-btn,
.right-btn,
.left-btn,
.down-btn{
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: 50%;
  background-color: transparent;
  border: none;
}

  
  .up-btn{

    background-image: url("../assets//Images/W.png");
  
  }
  .left-btn{
    
    background-image: url("../assets//Images/A.png");
  
  }
  .right-btn{
 
    background-image: url("../assets//Images/D.png");

  }
  .down-btn{

    background-image: url("../assets//Images/S.png");

  }

 .control-buttons button:active{
    transform: scale(0.9);
    background-color: none;
    border: none;

  }


  .maze-control-bar{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 100%;
  }

.mobile-title{
  display: flex;
  flex-direction: column;
  gap: 28px
}

  .restart-btn{
    width: 66px;
    height: 50px;
    background-color: white;
    box-shadow: 4px 4px 0px black;
    border: 1px solid black;
    
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #9e9e9e
    }
  }

  .leave-btn{
    width: 120px;
    height: 50px;
    background-color: white;
    box-shadow: 4px 4px 0px black;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    &:hover {
      background-color: #9e9e9e
    }
  }

  @media only screen and (max-width: 768px) {
    .restart-btn:hover {
      background-color: white;
    }
    .restart-btn:active {
      background-color: #9e9e9e
    }
    
  }

  @media (max-width: 768px) {
   .App-maze{
    width: 100%;
   } 
  .maze-control-bar{
    gap: 0;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
   
   
  }

  .control-buttons{
    margin-bottom: 20px;
  }

  #maze{
    margin-top: 30px;
  }
  .leave-btn{
    width: 54px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    bottom: 25%;
    left: 12%;
  }
  }