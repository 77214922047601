html,body {
    width:100%;
    height:100%;
    margin:0; 
    padding:0;
    display: flex;
    flex: 1;
    overflow:scroll;
    z-index: -213123213;
    font-family: Open Sans, sans-serif;
}

#root{
    width:100%;
    height:100%;
    margin:0; 
    padding:0;
    display: flex;
    flex: 1;
    overflow:scroll;
    z-index: -213123213;

}

#root::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#root {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
html::-webkit-scrollbar {
    display: none;
}
body::-webkit-scrollbar {
    display: none;
}

html,body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
