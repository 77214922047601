.game-scene {
  width: calc(100% - 336px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  gap: 20px;
  overflow-y: hidden;
}

.maze-container {
  width: 100%;
  max-width: 1500px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.game-title {
  display: flex;
  align-items: center;
  padding-top: 20px;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  gap: 12px;
}

.taleas-highlight {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 4px 1px black;
  width: 238px;
  height: 50px;
  background-color: #ee6666;
  color: white;
}

.mobile-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.game-instructions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media (max-width: 768px) {
  .game-footer {
    display: block;
    margin: 0;
    margin-bottom: 20px;
  }
  .taleas-highlight {
    width: 170px;
    height: 40px;
    font-size: 26px;
  }
  .game-scene {
    padding-top: 10px;
    width: 100%;
    height: auto;
    gap: 20px;
    overflow-y: hidden; /* Disable vertical scrolling on body */
    touch-action: none; /* Disable touch actions */
    justify-content: flex-start;
  }
  .maze-container {
    height: 100%;
  }
  .game-title {
    padding: 0;
    margin: 0;
    width: 100%;
    justify-content: center;
  }

  .maze-container {
    width: 100%;
  }
}

@media (min-height: 868px) and (max-width: 768px) {
  .game-scene {
    min-height: 100vh;
    overflow-y: hidden; /* Disable vertical scrolling on body */
    touch-action: none; /* Disable touch actions */
    overflow: hidden;
  }
  .mobile-nav {
    margin-top: 40px;
  }
  .game-header {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .game-footer {
    margin-top: 40px;
  }
}
