.main-menu {
  display: flex;
  flex-direction: column;
  width: calc(100% - 336px);
  text-align: left;
  padding: 62px;
  padding-top: 20px;
  overflow: scroll;
  overflow-x: hidden;
}

ul {
  list-style-type: none;
}

.main-title {
  font-size: 34px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 32px;
}

.instructions {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 32px;
}

.red-badge {
  width: 110px;
  height: 40px;
  background-color: #ee6666;
  color: white;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  box-shadow: 4px 4px 0 black;
  font-weight: bold;

  margin-bottom: 52px;
}

.is-img {
  width: 44px;
}

.is-li {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 9px;
}

.instructions-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 0;
  margin-bottom: 52px;
}


.main-menu-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 34px;
}

.start-btn {
  width: 190px;
  height: 50px;
  background-color: #7750bf;
  font-size: 18px;
  font-family: 'Open Sans';
  border: 1px solid black;
  box-shadow: 4px 4px 0px black;
  color: #fff;
  cursor: pointer;
}
.start-btn:hover {
  background-color: #5e3aa0;
}

.dont-btn {
  width: 190px;
  height: 50px;
  background-color: white;
  font-size: 18px;
  font-family: 'Open Sans';
  border: 1px solid black;
  box-shadow: 4px 4px 0px black;
  color: black;
  cursor: pointer;
}

.dont-btn:hover {
  background-color: #c9c6c6;
}

.mm-body-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 100px;
  gap: 50px;
}

.go-to-apply {
  position: relative;
}

.sad-face {
  position: absolute;
  bottom: 0;
  left: 200px;
}

.go-to-game {
  position: relative;
}

.lines {
  position: absolute;
  top: -40px;
  left: 180px;
}

.arrow {
  position: absolute;
  top: -120px;
  left: -100px;

}

.wow {
  position: absolute;
  bottom: 10px;
  left: 200px;
}

@media (max-width: 768px) {
  .gift-image {
    display: none;
  }

  .main-menu {
    padding: 0;
    margin-left: 22px;
    margin-right: 22px;
    padding-top: 16px;
   
    width: 100%;
  }

  .main-title {
    font-size: 24px;
    margin-bottom: 32px;
  }

  .mm-body-container {
    padding: 0;
  }

  .main-menu-buttons {
   
    padding-bottom: 30px;
  }

  .main-menu-buttons {
    gap: 20px;
  }

  .arrow{
  display: none;
  }
}


@media (min-width: 1920px) {


.mm-body-container {
  display: flex;
  gap:220px;
  align-items: center;
  justify-content: flex-start;
  padding-right: 0;
}

.instructions{
  margin-right: 80px;
}

.arrow{
  display: none;
}

  .main-menu{
    width: calc(100% - 336px);
    padding: 100px;
    padding-left: 350px;
    align-items: flex-start;
 
  }


 
}